/* Refurbishment.scss */

/* Container and layout */
.serial-number-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* Title style */
.serial-number-upload-title {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2rem;
}

/* Field row container */
.field-row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
}

/* Label style */
.label {
    width: 150px;
    font-weight: bold;
    margin-right: 1rem;
    font-size: 1.1rem;
    text-align: right;
}

/* Input column for inputs + error messages */
.input-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Generic input field style */
.input-field {
    font-size: 1rem;
    padding: 0.4rem;
    width: 200px;
}

/* Selected file info */
.file-info {
    margin: 0.5rem 0;
    color: #555;
    font-style: italic;
}

/* Info sheet block */
.info-sheet {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    max-width: 600px;
}

/* Info button container & button */
.info-button-container {
    margin-bottom: 1rem;
}
.info-button {
    cursor: pointer;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
}

/* Button row container */
.button-row {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    width: 100%;
}

/* Upload & reset buttons */
.upload-button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
}
.reset-button {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
}

/* Message area */
.message {
    margin-top: 1rem;
    font-style: italic;
    background-color: #fff;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    text-align: center;
    width: 80%;
}

/* Error text styling */
.error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.3rem;
}
